<template>
  <div class="form-inner edit_inner">
    <div class="cant-answer"
         v-if="canAnswer == 0">
      <el-result icon="error"
                 title="错误提示"
                 :subTitle="cantMessage">
        <template slot="extra">
          <el-button type="primary"
                     size="medium"
                     @click="close">返回</el-button>
        </template>
      </el-result>
    </div>
    <template v-else>
      <div class="limit-page"
           v-if="startPassword == 1">
        <el-image>
          <div slot="error"
               class="image-slot">
            <i class="el-icon-lock"></i>
          </div>
        </el-image>
        <div class="pass-word">
          <el-input v-model="userInputPsd"
                    type="password"
                    placeholder="请输入答题密码"></el-input>
          <el-button type="primary"
                     @click="confirmPsw">提交</el-button>
        </div>
      </div>
      <template v-else>
        <div class="need_height">
          <div class="form-head"
               v-if="globalProperties.formHeader.isShow">
            <div v-if="globalProperties.formHeader.type === '文字'"
                 class="txt">{{globalProperties.formHeader.textCont}}</div>
            <div v-else
                 class="img">
              <img :src="globalProperties.formHeader.imgSrc">
            </div>
          </div>
          <div class="form-title"
               v-if="globalProperties.formTitle.isShow">{{globalProperties.formTitle.textCont}}</div>
          <div class="form-descript"
               v-if="globalProperties.formDescript.isShow">{{globalProperties.formDescript.textCont}}</div>
          <div class="parent-list">
            <div class="parent-item"
                 v-for="(item,index) in list"
                 :key="index">
              <div class="c-item"
                   v-for="(o,i) in item.children"
                   :key="i">
                <ybdInput v-if="o.type == 'input'"
                          :detial="o" />
                <ybdTextarea v-else-if="o.type == 'textarea'"
                             :detial="o" />
                <ybdRadio v-else-if="o.type == 'radio'"
                          :detial="o" />
                <ybdCheckbox v-else-if="o.type == 'checkbox'"
                             :detial="o" />
                <ybdSelect v-else-if="o.type == 'select'"
                           :detial="o" />
                <ybdNumber v-else-if="o.type == 'number'"
                           :detial="o" />
                <ybdDate v-else-if="o.type == 'date'"
                         :detial="o" />
                <ybdLocation v-else-if="o.type == 'location'"
                             :detial="o" />
                <ybdTable v-else-if="o.type == 'table'"
                          :detial="o" />
                <ybdImage v-else-if="o.type == 'image'"
                          :detial="o" />
                <ybdAttachment v-else-if="o.type == 'attachment'"
                               :detial="o" />
                <ybdDivider v-else-if="o.type == 'divider'"
                            :detial="o" />
              </div>
            </div>
          </div>
          <div class="submit_btn"
               v-if="globalStyle.submitBtnStyle">
            <el-button type="primary"
                       @click="formSubmit">{{globalStyle.submitBtnStyle.text}}</el-button>
          </div>
        </div>
      </template>
    </template>
    <iframe :src="jzHost + '/forminitSuccessback.html?formId=' + formId + '&height=' + formHeight + '&width=' + formWidth"
            frameborder="0"
            width="0"
            height="0"></iframe>
  </div>
</template>
<script>
import $ from "jquery";
import ybdInput from "@/components/ybdInput";
import ybdTextarea from "@/components/ybdTextarea";
import ybdRadio from "@/components/ybdRadio";
import ybdCheckbox from "@/components/ybdCheckbox";
import ybdSelect from "@/components/ybdSelect";
import ybdNumber from "@/components/ybdNumber";
import ybdDate from "@/components/ybdDate";
import ybdLocation from "@/components/ybdLocation";
import ybdTable from "@/components/ybdTable";
import ybdImage from "@/components/ybdImage";
import ybdAttachment from "@/components/ybdAttachment";
import ybdDivider from "@/components/ybdDivider";
import { get, postJson } from "/src/services/ajax.js";
export default {
  components: {
    ybdInput,
    ybdTextarea,
    ybdRadio,
    ybdCheckbox,
    ybdSelect,
    ybdNumber,
    ybdDate,
    ybdLocation,
    ybdTable,
    ybdImage,
    ybdAttachment,
    ybdDivider,
  },
  data () {
    return {
      identityType: sessionStorage.getItem('identityType') ? sessionStorage.getItem('identityType') : '',
      formId: sessionStorage.getItem('formId') ? sessionStorage.getItem('formId') : '',
      jzHost: '',
      globalStyle: {
        themeColor: '',
        formWidth: '',
        contStyle: {},
        itemStyle: {},
        labelStyle: {},
        submitBtnStyle: {}
      },
      globalProperties: {
        formHeader: {},
        formBg: {},
        formTitle: {},
        formDescript: {}
      },
      mGlobalStyle: {
        formHeader: {},
        formBg: {},
        formTitle: {},
        formDescript: {},
        contStyle: {},
        itemStyle: {},
        labelStyle: {},
        inputStyle: {},
        submitBtnStyle: {}
      },
      list: [],
      canAnswer: 1,
      cantMessage: '',
      startPassword: 0,
      password: '',
      userInputPsd: '',
      id: '',
      formWidth: '',
      formHeight: ''
    }
  },
  watch: {
    list () {
      this.$nextTick(function () {
        this.formWidth = $(".need_height").outerWidth(true);
        this.formHeight = $(".need_height").outerHeight(true);
      });
    }
  },
  mounted () {
    const { identityType, formId } = this.$route.query;
    if (identityType && formId) {
      this.loadFormSeting(formId);
      this.formId = formId;
    } else {
      if (this.identityType && this.formId) {
        this.loadFormSeting(this.formId);
      } else {
        console.log('去登录！');
      }
    }
    this.jzHost = document.referrer;
  },
  methods: {
    // 获取表单设置属性
    loadFormSeting (formId) {
      const _this = this;
      get('/loadFormSeting.api', { formId }).then(res => {
        if (res.code == 200) {
          if (res.data) {
            _this.startPassword = res.data.startPassword ? res.data.startPassword : 0;
            _this.password = res.data.password ? res.data.password : '';
            if (res.data.setTime && res.data.setTime == 1) {
              if (new Date(res.data.start_time).getTime() > new Date().getTime() || new Date(res.data.end_time).getTime() < new Date().getTime()) {
                _this.canAnswer = 0;
                _this.cantMessage = '抱歉，不在答题时间内！';
                return false;
              }
            }
          }
          if (_this.identityType == 'admin') {
            _this.getTplFormData(_this.formId);
          } else {
            _this.getUserFormData(_this.formId);
          }
        } else {
          _this.$message.error(res.message);
        }

      }).catch(err => {
        _this.$message.error(err);
      })
    },
    // 获取模板表单数据
    getTplFormData (formId) {
      const _this = this;
      get('/loadFormModelPreview.api', { formId }).then(res => {
        if (res.code == 200) {
          _this.formId = res.data.formData._id;
          _this.globalStyle = res.data.formData.globalStyle;
          _this.globalProperties = res.data.formData.globalProperties;
          _this.mGlobalStyle = res.data.formData.mGlobalStyle;
          _this.list = res.data.formData.list;
          _this.loadStyle();
        } else {
          _this.$message.error(res.message);
        }
      }).catch(err => {
        _this.$message.error(err);
      })
    },
    // 获取用户表单数据
    getUserFormData (formId) {
      const _this = this;
      get('/loadFormUserPreview.api', { formId }).then(res => {
        if (res.code == 200) {
          _this.formId = res.data.formData._id;
          _this.globalStyle = res.data.formData.globalStyle;
          _this.globalProperties = res.data.formData.globalProperties;
          _this.mGlobalStyle = res.data.formData.mGlobalStyle;
          _this.list = res.data.formData.list;
          _this.loadStyle();
        } else {
          _this.$message.error(res.message);
        }
      }).catch(err => {
        _this.$message.error(err);
      })
    },
    // 加载样式
    loadStyle () {
      this.$nextTick(function () {
        // 表单样式
        document.documentElement.style.setProperty("--theme-custom-color", this.globalStyle.themeColor);
        const formBg = this.globalProperties.formBg;
        let formBgStr = '';
        if (formBg.isShow) {
          if (formBg.type == '颜色') {
            formBgStr = formBg.color;
          } else {
            if (formBg.imgSrc) {
              formBgStr = 'url(' + formBg.imgSrc + ')';
            }
          }
        } else {
          formBgStr = '#fff';
        }
        $(".form-inner").css({
          width: this.globalStyle.formWidth + 'px',
          background: formBgStr,
          backgroundSize: 'cover'
        })
        // 页眉
        const formHeaderObj = this.globalProperties.formHeader.textStyle;
        $(".form-head .txt").css({
          fontSize: formHeaderObj.fontSize + "px",
          color: formHeaderObj.color,
          fontWeight: formHeaderObj.fontWight,
          fontStyle: formHeaderObj.fontStyle,
          textDecoration: formHeaderObj.textDecoration,
          textAlign: formHeaderObj.textAlign == "居左" ? "left" : formHeaderObj.textAlign == "居中" ? "center" : "right",
          backgroundColor: formHeaderObj.backgroundColor,
          padding: formHeaderObj.paddingVertical + "px " + formHeaderObj.paddingHorizontal + "px",
        });
        $(".form-head .img").css({
          height: this.globalProperties.formHeader.imgHeight + 'px'
        })
        // 表单标题样式
        const formTitleObj = this.globalProperties.formTitle.textStyle;
        let formTitleBgStr = formTitleObj.bgType == "默认" ? "unset" : formTitleObj.bgType == "颜色" ? formTitleObj.backgroundColor : "url(" + formTitleObj.backgroundImage + ")";
        $(".form-title").css({
          fontSize: formTitleObj.fontSize + "px",
          color: formTitleObj.color,
          fontWeight: formTitleObj.fontWight,
          fontStyle: formTitleObj.fontStyle,
          textDecoration: formTitleObj.textDecoration,
          padding: formTitleObj.paddingTop + "px " + formTitleObj.paddingHorizontal + "px " + formTitleObj.paddingBottom + 'px',
          background: formTitleBgStr,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        });
        // 表单描述样式
        const formDescriptObj = this.globalProperties.formDescript.textStyle;
        $(".form-descript").css({
          fontSize: formDescriptObj.fontSize + "px",
          color: formDescriptObj.color,
          fontWeight: formDescriptObj.fontWight,
          fontStyle: formDescriptObj.fontStyle,
          textDecoration: formDescriptObj.textDecoration,
          padding: formDescriptObj.paddingVertical + "px " + formDescriptObj.paddingHorizontal + "px",
        });
        // 内容区样式
        const contStyleObj = this.globalStyle.contStyle;
        $(".parent-list").css({
          padding: contStyleObj.paddingTop + "px " + contStyleObj.paddingHorizontal + "px " + contStyleObj.paddingBottom + 'px',
        });
        // 提交按钮
        const submitBtnObj = this.globalStyle.submitBtnStyle;
        $(".submit_btn .el-button").css({
          width: submitBtnObj.width + "px",
          height: submitBtnObj.height + "px",
          borderWidth: submitBtnObj.borderWidth + "px",
          borderStyle: submitBtnObj.borderStyle,
          borderColor: submitBtnObj.borderColor,
          borderRadius: submitBtnObj.borderRadius + "px",
          backgroundColor: submitBtnObj.backgroundColor,
          fontSize: submitBtnObj.fontSize + "px",
          fontWeight: submitBtnObj.fontWight,
          fontStyle: submitBtnObj.fontStyle,
          color: submitBtnObj.color,
          textDecoration: submitBtnObj.textDecoration,
        });
        $(".submit_btn").css({
          padding: submitBtnObj.paddingTop + "px " + submitBtnObj.paddingHorizontal + "px " + submitBtnObj.paddingBottom + 'px',
          justifyContent: submitBtnObj.textAlign == '居左' ? 'flex-start' : submitBtnObj.textAlign == '居中' ? 'center' : 'flex-end',
        });
      })
    },
    // 提交密码
    confirmPsw () {
      if (this.userInputPsd == '') {
        this.$message.error('请输入密码！');
      } else {
        if (this.userInputPsd != this.password) {
          this.$message.error('密码错误！');
          this.userInputPsd = '';
        } else {
          this.startPassword = 0;
          this.loadStyle();
        }
      }
    },
    close () {
      window.location.href = "about:blank";
      window.close();
    },
    // 表单提交
    formSubmit () {
      const _this = this;
      for (let i = 0; i < _this.list.length; i++) {
        const element = _this.list[i];
        for (let j = 0; j < element.children.length; j++) {
          const e = element.children[j];
          if (e.required && (e.value === '' || e.value.length == 0)) {
            _this.$message.error(`${e.label}不能为空！`);
            return false;
          }
        }
      }
      let jsonData = {
        _id: _this.formId,
        list: _this.list
      };
      postJson('/formUserSubmit.api', jsonData).then(res => {
        if (res.code == 200) {
          _this.$message({
            type: 'success',
            message: '提交成功！',
            onClose () {
              location.reload();
            }
          })
        } else {
          _this.$message.error(res.message);
        }
      }).catch(err => {
        _this.$message.error(err);
      })

    }
  }
};
</script>
<style lang="less" scoped>
.form-inner {
  height: calc(100% - 40px);
  margin: 20px auto;
  overflow-y: auto;
  .form-head {
    .img {
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .form-title {
    text-align: center;
  }
  .parent-list {
    .parent-item {
      display: flex;
      .c-item {
        flex: 1;
        overflow: hidden;
      }
      .c-item + .c-item {
        .item {
          border-left: none !important;
        }
      }
    }
  }
  .submit_btn {
    display: flex;
  }
  .limit-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 0 20vh;
    .el-image {
      .el-icon-lock {
        font-size: 100px;
        color: #ccc;
      }
    }
    .pass-word {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5vh 0 0;
      .el-input {
        margin: 0 10px 0 0;
      }
    }
  }
  .cant-answer {
    height: 100%;
    .el-result {
      height: 90%;
    }
  }
}
</style>