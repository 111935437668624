<template>
   <el-cascader class="ybd_m_location" popper-class="ybd-cus-location" :options="options" :placeholder="detial.placeholder" v-model="locationSelectedOptions" separator="" :clearable="true"></el-cascader>
</template>
<script>
import $ from "jquery";
import { regionData  } from 'element-china-area-data'
export default {
	props: {
		detial: {
			type: Object,
		},
		cusStyle: {
			type: Object,
		},
	},
	data() {
		return {
			options: regionData ,
			locationSelectedOptions: []
		};
	},
	watch: {
		"cusStyle.inputStyle": {
			handler() {
				this.$nextTick(function () {
					const styleStr = this.cusStyle.inputStyle;
					$(".ybd_m_location .el-input__inner").css({
						height: styleStr.height + "px",
						borderWidth: styleStr.borderWidth + "px",
						borderStyle: styleStr.borderStyle,
						borderColor: styleStr.borderColor,
						borderRadius: styleStr.borderRadius + "px",
						backgroundColor: styleStr.backgroundColor,
					});
				});
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
<style lang="less" scoped>
.ybd_m_location{
	width: 100%;
}
</style>