import { render, staticRenderFns } from "./previewPC.vue?vue&type=template&id=ca2e5980&scoped=true&"
import script from "./previewPC.vue?vue&type=script&lang=js&"
export * from "./previewPC.vue?vue&type=script&lang=js&"
import style0 from "./previewPC.vue?vue&type=style&index=0&id=ca2e5980&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca2e5980",
  null
  
)

export default component.exports