<template>
	<el-select class="ybd_m_select" v-model="detial.value" :placeholder="detial.placeholder" popper-class="ybd-cus-select">
		<el-option v-for="item in detial.options" :key="item.value" :label="item.value" :value="item.value"></el-option>
	</el-select>
</template>
<script>
import $ from "jquery";
export default {
	props: {
		detial: {
			type: Object,
		},
		cusStyle: {
			type: Object,
		},
	},
	watch: {
		"cusStyle.inputStyle": {
			handler() {
				this.$nextTick(function () {
					const styleStr = this.cusStyle.inputStyle;
					$(".ybd_m_select .el-input__inner").css({
						height: styleStr.height + "px",
						borderWidth: styleStr.borderWidth + "px",
						borderStyle: styleStr.borderStyle,
						borderColor: styleStr.borderColor,
						borderRadius: styleStr.borderRadius + "px",
						backgroundColor: styleStr.backgroundColor,
					});
				});
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
<style lang="less" scoped>
.ybd_m_select{
	width: 100%;
}
</style>