import { render, staticRenderFns } from "./ybdDividerM.vue?vue&type=template&id=38550a00&scoped=true&"
import script from "./ybdDividerM.vue?vue&type=script&lang=js&"
export * from "./ybdDividerM.vue?vue&type=script&lang=js&"
import style0 from "./ybdDividerM.vue?vue&type=style&index=0&id=38550a00&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38550a00",
  null
  
)

export default component.exports