<template>
	<div class="m_avatar">
		<el-upload class="avatar-uploader" ref="pictureUpload" action="/api/formdesign/upload.api" :headers="{Authorization: 'Form Bearer ' + formToken}" accept="image/*" list-type="picture-card"  :limit="detial.allowNum" :before-upload="beforeAvatarUpload" :on-exceed="masterFileMax" :file-list="detial.value" :on-success="handleImgSuccess">
			<i class="el-icon-plus avatar-uploader-icon"></i>
			<div class="txt">{{detial.inputStyle.text}}</div>
		</el-upload>
	</div>
</template>
<script>
export default {
	props: {
		detial: {
			type: Object,
		},
	},
	data() {
		return {
			formToken: sessionStorage.getItem('token')?sessionStorage.getItem('token'):'',
		};
	},
	methods: {
		beforeAvatarUpload(file){
			const isLt5M = file.size / 1024 / 1024 < this.detial.allowSize;
			if (!isLt5M) {
				this.$message.error(`大小不能超过 ${this.detial.allowSize}MB!`);
			}
			return isLt5M;
		},
		masterFileMax() {
			this.$message.error(`请最多上传 ${this.detial.allowNum} 个文件。`);
		},
		handleImgSuccess(){
			this.detial.value = this.$refs.pictureUpload.uploadFiles;
		}
	},
};
</script>
<style lang="less" scoped>
.m_avatar{
	display: flex;
	.avatar_item {
		margin: 0 10px 0 0;
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
	::v-deep .avatar-uploader {
		.el-upload {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100px;
			height: 100px;
			line-height: 1;
			border: 1px dashed #ddd;
			border-radius: 4px;
			background-color: #fff;
			font-size: 14px;
			color: #999;
			.avatar-uploader-icon {
				font-size: 28px;
				color: inherit;
			}
			.txt {
				margin: 10px 0 0;
			}
			&:hover {
				border-color: var(--theme-custom-color) !important;
				color: var(--theme-custom-color) !important;
			}
		}
		.el-upload-list--picture-card{
			float: left;
			.el-upload-list__item{
				width: 100px;
				height: 100px;
				border-color: #ddd;
				.el-upload-list__item-thumbnail{
					object-fit: contain;
				}
			}
		}
	}
}
</style>