<template>
	<el-checkbox-group class="ybd_m_checkbox" :class="{horizontal: detial.inputStyle.orientationMode == '纵向'}" v-model="detial.value">
		<el-checkbox v-for="(item,index) in detial.options" :key="index" :label="index">{{item.value}}</el-checkbox>
	</el-checkbox-group>
</template>
<script>
import $ from "jquery";
export default {
	props: {
		detial: {
			type: Object,
		},
	},
	computed: {
		watchAttr() {
			return {
				...this.detial.inputStyle,
				options: this.detial.options,
			};
		},
	},
	watch: {
		watchAttr: {
			handler() {
				this.$nextTick(function () {
					const styleStr = this.detial.inputStyle;
					if (styleStr.orientationMode == "横向") {
						$(".ybd_m_checkbox .el-checkbox").css({
							display: "inline-block",
							margin: "12px 0",
							marginRight: styleStr.intervalHorizontal + "px",
						});
					} else {
							$(".ybd_m_checkbox .el-checkbox").css({
								display: "block",
								margin: 0,
								marginBottom: styleStr.intervalVertical + "px",
						});
					}
				});
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
<style lang="less" scoped>
.ybd_m_checkbox{
	.el-checkbox {
		margin: 10px 30px 12px 0;
	}
}
.horizontal {
	.el-checkbox {
		&:last-child {
			margin-bottom: 0 !important;
		}
	}
}
</style>
