<template>
	<div class="mform-inner edit_inner">
			<div class="cant-answer" v-if="canAnswer == 0">
				<el-result icon="error" title="错误提示" :subTitle="cantMessage">
					<template slot="extra">
						<el-button type="primary" size="medium" @click="close">返回</el-button>
					</template>
				</el-result>
			</div>
			<template v-else>
				<div class="limit-page" v-if="startPassword == 1">
					<el-image>
						<div slot="error" class="image-slot">
							<i class="el-icon-lock"></i>
						</div>
					</el-image>
					<div class="pass-word">
						<el-input v-model="userInputPsd" type="password" placeholder="请输入答题密码"></el-input>
						<el-button type="primary" @click="confirmPsw">提交</el-button>
					</div>
				</div>
				<template v-else>
					<div class="mform-logo"></div>
					<div class="mform-head" v-if="globalProperties.formHeader.isShow">
						<div v-if="globalProperties.formHeader.type === '文字'" class="txt" key="mFormHeadText">{{globalProperties.formHeader.textCont}}</div>
						<div v-else class="img" key="mFormHeadImg">
							<img v-if="globalProperties.formHeader.imgSrc" :src="globalProperties.formHeader.imgSrc">
						</div>
					</div>
					<div class="medit-head">
						<div class="mform-title" v-if="globalProperties.formTitle.isShow">{{globalProperties.formTitle.textCont}}</div>
						<div class="mform-descript" v-if="globalProperties.formDescript.isShow">{{globalProperties.formDescript.textCont}}</div>
					</div>
					<div class="mform-widget">
						<div class="mform-list">
							<div class="mform-item" v-for="(item,index) in mList" :key="index">
								<div class="mform-sub" v-for="(o,i) in item.children" :key="i">
									<mWidget :detial="o" :cusStyle="mGlobalStyle" />		
								</div>
							</div>
						</div>
					</div>
					<div class="submit_btn msubmit_btn">
						<el-button type="primary" @click="formSubmit">{{globalStyle.submitBtnStyle.text}}</el-button>
					</div>
				</template>
			</template>
	</div>
</template>
<script>
import $ from "jquery";
import { get,postJson } from "/src/services/ajax.js";
import mWidget from "@/components/mWidget";
export default {
	components: {
		mWidget,
	},
	data() {
		return {
			sizeRatio: 1,
			identityType: sessionStorage.getItem('identityType')?sessionStorage.getItem('identityType'):'',
			formId: sessionStorage.getItem('formId')?sessionStorage.getItem('formId'):'',
			globalStyle: {
				themeColor: '',
				formWidth: '',
				contStyle: {},
				itemStyle: {},
				labelStyle: {},
				submitBtnStyle: {}
			},
			globalProperties: {
				formHeader: {},
				formBg: {},
				formTitle: {},
				formDescript: {}
			},
			mGlobalStyle: {
				formHeader: {},
				formBg: {},
				formTitle: {},
				formDescript: {},
				contStyle: {},
				itemStyle: {},
				labelStyle: {},
				inputStyle: {},
				submitBtnStyle: {}
			},
			mList: [],
			canAnswer: 1,
			cantMessage: '',
			startPassword: 0,
			password: '',
			userInputPsd: ''
		}
	},
	mounted(){
		const {identityType,formId} = this.$route.query;
		if(identityType && formId){
			this.loadFormSeting(formId);
			this.formId = formId;
      this.identityType = identityType;
		}else{
			if(this.identityType && this.formId){
				this.loadFormSeting(this.formId);
			}else{
				console.log('去登录！');
			}
		}
	},
	methods: {
			// 获取表单设置属性
		loadFormSeting(formId){
			const _this = this;
			get('/loadFormSeting.api',{formId}).then(res => {
				if(res.code == 200){
					if(res.data){
						_this.startPassword = res.data.startPassword?res.data.startPassword:0;
						_this.password = res.data.password?res.data.password:'';
						if(res.data.setTime && res.data.setTime == 1){
							if(new Date(res.data.start_time).getTime() > new Date().getTime() || new Date(res.data.end_time).getTime() < new Date().getTime()){
								_this.canAnswer = 0;
								_this.cantMessage = '抱歉，不在答题时间内！';
								return false;
							}
						}
					}
					if(_this.identityType == 'admin'){
						_this.getTplFormData(_this.formId);
					}else{
						_this.getUserFormData(_this.formId);
					}
				}else{
					_this.$message.error(res.message);
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 获取模板表单数据
		getTplFormData(formId){
			const _this = this;
			get('/loadFormModelPreview.api',{formId}).then(res => {
				if(res.code == 200){
					_this.formId = res.data.formData._id;
					_this.globalStyle = res.data.formData.globalStyle;
					_this.globalProperties = res.data.formData.globalProperties;
					_this.mGlobalStyle = res.data.formData.mGlobalStyle;
					_this.mList = res.data.formData.list;
					_this.loadStyle();
				}else{
					_this.$message.error(res.message);
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 获取用户表单数据
		getUserFormData(formId){
			const _this = this;
			get('/loadFormUserPreview.api',{formId}).then(res => {
				if(res.code == 200){
					_this.formId = res.data.formData._id;
					_this.globalStyle = res.data.formData.globalStyle;
					_this.globalProperties = res.data.formData.globalProperties;
					_this.mGlobalStyle = res.data.formData.mGlobalStyle;
					_this.mList = res.data.formData.list;
					_this.loadStyle();
				}else{
					_this.$message.error(res.message);
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 加载样式
		loadStyle(){
			this.$nextTick(function () {
				const mFormHeadObj = this.mGlobalStyle.formHeader.textStyle;
				$(".mform-head .txt").css({
					fontSize: mFormHeadObj.fontSize + "px",
					color: mFormHeadObj.color,
					fontWeight: mFormHeadObj.fontWight,
					fontStyle: mFormHeadObj.fontStyle,
					textDecoration: mFormHeadObj.textDecoration,
					textAlign: mFormHeadObj.textAlign == "居左"? "left":mFormHeadObj.textAlign == "居中"?"center":"right",
					backgroundColor: mFormHeadObj.backgroundColor,
					padding: mFormHeadObj.paddingVertical + "px " + mFormHeadObj.paddingHorizontal + "px",
				});
				$(".mform-head .img img").css({
					height: this.mGlobalStyle.formHeader.imgHeight + 'px'
				})
				const mFormTitleObj = this.mGlobalStyle.formTitle.textStyle;
				let mFormTitleBg = mFormTitleObj.bgType == "默认"? "unset":mFormTitleObj.bgType == "颜色"? mFormTitleObj.backgroundColor:"url(" + mFormTitleObj.backgroundImage + ")";
				$(".mform-title").css({
					fontSize: mFormTitleObj.fontSize + "px",
					color: mFormTitleObj.color,
					fontWeight: mFormTitleObj.fontWight,
					fontStyle: mFormTitleObj.fontStyle,
					textDecoration: mFormTitleObj.textDecoration,
					padding: mFormTitleObj.paddingTop + "px " + mFormTitleObj.paddingHorizontal + "px " + mFormTitleObj.paddingBottom + 'px',
					background: mFormTitleBg,
					backgroundSize: 'cover',
					backgroundPosition: 'center'
				})
				const mFormDescriptObj = this.mGlobalStyle.formDescript.textStyle;
				$(".mform-descript").css({
					fontSize: mFormDescriptObj.fontSize + "px",
					color: mFormDescriptObj.color,
					fontWeight: mFormDescriptObj.fontWight,
					fontStyle: mFormDescriptObj.fontStyle,
					textDecoration: mFormDescriptObj.textDecoration,
					padding: mFormDescriptObj.paddingVertical + "px " + mFormDescriptObj.paddingHorizontal + "px",
				});
				const mFormContObj = this.mGlobalStyle.contStyle;
				$(".mform-widget").css({
					padding: mFormContObj.paddingTop + "px " + mFormContObj.paddingHorizontal + "px " + mFormContObj.paddingBottom + 'px',
				});
				const mFormBgObj = this.mGlobalStyle.formBg;
				let mFormBg = '';
				if(mFormBgObj.isShow){
					if(mFormBgObj.type == '颜色'){
						mFormBg = mFormBgObj.color;
					}else{
						if(mFormBgObj.imgSrc){
							mFormBg = 'url(' + mFormBgObj.imgSrc + ')';
						}
					}
				}else{
					mFormBg = '#fff';
				}
				$(".mform-inner").css({
					background: mFormBg,
					backgroundSize: 'cover'
				});
				const mSubmitBtnObj = this.mGlobalStyle.submitBtnStyle;
				$(".msubmit_btn .el-button").css({
					width: mSubmitBtnObj.width + "px",
					height: mSubmitBtnObj.height + "px",
					borderWidth: mSubmitBtnObj.borderWidth + "px",
					borderStyle: mSubmitBtnObj.borderStyle,
					borderColor: mSubmitBtnObj.borderColor,
					borderRadius: mSubmitBtnObj.borderRadius + "px",
					backgroundColor: mSubmitBtnObj.backgroundColor,
					fontSize: mSubmitBtnObj.fontSize + "px",
					fontWeight: mSubmitBtnObj.fontWight,
					fontStyle: mSubmitBtnObj.fontStyle,
					color: mSubmitBtnObj.color,
					textDecoration: mSubmitBtnObj.textDecoration,
				});
				$(".msubmit_btn").css({
					padding: mSubmitBtnObj.paddingTop + "px " + mSubmitBtnObj.paddingHorizontal + "px " + mSubmitBtnObj.paddingBottom + 'px',
					justifyContent: mSubmitBtnObj.textAlign == '居左'?'flex-start':mSubmitBtnObj.textAlign == '居中'?'center':'end',
				});
			})
		},
		// 提交密码
		confirmPsw(){
			if(this.userInputPsd == ''){
				this.$message.error('请输入密码！');
			}else{
				if(this.userInputPsd != this.password){
					this.$message.error('密码错误！');
					this.userInputPsd = '';
				}else{
					this.startPassword = 0;
					this.loadStyle();
				}
			}
		},
		close(){
			window.location.href="about:blank";
			window.close();
		},
		// 表单提交
		formSubmit(){
			const _this = this;
			for (let i = 0; i < _this.mList.length; i++) {
				const element = _this.mList[i];
				for (let j = 0; j < element.children.length; j++) {
					const e = element.children[j];
					if(e.required && (e.value === '' || e.value.length == 0)){
						_this.$message.error(`${e.label}不能为空！`);
						return false;
					}
				}
			}
			let jsonData = {
				formId: _this.formId,
				list: _this.mList
			};
			postJson('/formUserSubmit.api',jsonData).then(res => {
				if(res.code == 200){
					_this.$message({
						type: 'success',
						message: '提交成功！',
						onClose(){
							location.reload();
						}
					})
				}else{
					_this.$message.error(res.message);
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		
		}
	}
};
</script>
<style lang="less" scoped>
	.mform-inner{
		width: 100%;
		height: 100%;
		border: 1px solid #ddd;
		overflow-y: auto;
		.mform-head{
			.img{
				img{
					width: 100%;
				}
			}
		}
		.mform-title{
			text-align: center;
		}
		.msubmit_btn{
			display: flex;
		}
		.limit-page{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 100%;
			padding: 0 0 20vh;
			.el-image{
				.el-icon-lock{
					font-size: 70px;
					color: #ccc;
				}
			}
			.pass-word{
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 5vh 0 0;
				.el-input{
					margin: 0 10px 0 0;
				}
			}
		}
		.cant-answer{
			height: 100%;
			.el-result{
				height: 90%;
			}
		}
	}
</style>